<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Stat
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      statData: null,
      status: null,
      auth: null,
    };
  },
  methods: {
    getShopee() {
      api.get("integracoes/shopee").then((res) => {
        this.status = res.data.status;
        this.auth = res.data.auth;

        this.statData = [
          {
            title: "Status",
            value: res.data.status,
          },
        ];
      });
    },
  },
  mounted() {
    this.getShopee();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/integracoes">Integrações</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Shopee</li>
    </ol>

    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Shopee</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!status">
      <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
    </div>

    <div class="row">
      <div v-for="(stat, index) of statData" :key="index" class="col-md-4 col-lg-4 col-xl-4">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="status && status == 'inactive'" class="card">
      <div class="card-body text-center">
        Para integrar a Shopee ao sistema, por favor, autorize clicando abaixo.<br><br>
        <a v-if="auth" :href="auth" class="btn btn-default btn-lg text-uppercase px-5">Autorizar</a>
      </div>
    </div>
    <div v-else-if="status && status == 'active'" class="card">
      <div class="card-body text-center">
        A integração com a Shopee está ativa.
      </div>
    </div>
  </Layout>
</template>
